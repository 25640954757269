import React, { useRef, useLayoutEffect } from "react";
import SidePanel from "./SidePanel";
import { ReactComponent as Rocket } from "../images/rocket.svg";
import { gsap, Power3 } from "gsap";

const Home = () => {
  const imageRef = useRef(null);
  const containerRef = useRef(null);
  useLayoutEffect(() => {
    const tl = gsap.timeline();
    tl.to(containerRef.current, { opacity: 1, duration: 1.4 }).from(
      imageRef.current,
      { y: 1200, duration: 1.8, ease: Power3.easeOut },
      0.2
    );
  }, []);
  return (
    <>
      <section
        className="section atf no-overflow wf-section"
        ref={containerRef}
      >
        <div className="container atf-home">
          <header className="atf-header-block">
            <h1 className="heading space-font" style={{ marginBottom: "0" }}>
              Cryptonian War
            </h1>
            <div className="div-block-3">
              <div className="paragraph-wrapper">
                <p className="paragraph paragraph-heading">
                  Galaxies have come and gone, but there has never been an
                  uprising of this magnitude against the establishment…those
                  that attempt to control who we are, how we live, and what we
                  value.
                  <br /> The Cryptonian war has begun.
                  <br /> Cryptonian Warriors are superhero apes living on the
                  Ethereum blockchain uniting to conquer those that limit the
                  rise of Crypto.
                  <br /> Our 9,888 randomly generated Warriors will defeat the
                  naysayers, outpace the day traders, and defend your Crypto
                  future by fighting gas spikes, offering upgradable Warriors,
                  and adapting new elements, weapons, features, and powers.
                  <br />
                  Join our fight and become a Cryptonian Citizen.
                </p>
                <div
                  data-w-id="d5eea231-eb51-ede4-0b43-2f623e3d7311"
                  className="button-contain-atf home-button"
                >
                  <a
                    data-w-id="004dc147-bfbf-3733-ea4f-c4b697d7f798"
                    href="https://discord.gg/H5NnngKFkG"
                    target="_blank"
                    className="button-bigger w-inline-block"
                    style={{
                      backgroundColor: "#b67318",
                      borderRadius: "2px",
                    }}
                  >
                    <div className="button-text">
                      <span style={{ margin: "0 0.25rem 0 0" }}>
                        <Rocket />
                      </span>
                      Join Our Discord
                    </div>
                  </a>
                </div>
              </div>
              <div className="div-block-26" ref={imageRef}>
                {/* <div className="ape-atf"> */}
                <img
                  src={require("../images/woman-soldier.png")}
                  alt="nft 2"
                  className="image home-img"
                  style={{ objectFit: "fill", position: "absolute" }}
                  id="header_img-2"
                />
                <img
                  src={require("../images/man2-soldier.png")}
                  alt="nft 1"
                  className="image contain home-img"
                  style={{ objectFit: "fill" }}
                  id="header_img-1"
                />
              </div>
              {/* <div className="div-block-18">
                  <img
                    src={require("../images/woman-soldier.png")}
                    alt="nft 2"
                    className="image home-img"
                    style={{ objectFit: "fill" }}
                    id="header_img-2"
                  />
                </div> */}
            </div>
          </header>
        </div>
        <SidePanel />
      </section>
    </>
  );
};

export default Home;
