import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
// import {ReactComponent as Arrow} from "../images/Arrow-Down.svg"

const SidePanel = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const panelRef = useRef(null);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (
      window.innerHeight + scrollPosition >=
      0.95 * document.body.offsetHeight
    ) {
      panelRef.current.style.opacity = 0;
    } else {
      panelRef.current.style.opacity = 1;
    }
  }, [scrollPosition]);

  return (
    <>
      <div className="side-panel" ref={panelRef}>
        <div className="side-panel-wrap">
          <a
            href="https://twitter.com/cryptonianwar"
            target="_blank"
            className="side-panel-link w-inline-block"
          >
            <div className="side-panel-link-text">
              <span style={{ margin: "0 0.5rem" }}>
                <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
              </span>
              Twitter
            </div>
          </a>
          <a
            href="https://www.instagram.com/cryptonianwar/"
            target="_blank"
            className="side-panel-link w-inline-block"
          >
            <div className="side-panel-link-text">
              <span style={{ margin: "0 0.5rem" }}>
                <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
              </span>
              Instagram
            </div>
          </a>
          <a
            href="https://discord.gg/H5NnngKFkG"
            target="_blank"
            className="side-panel-link no-margin-right w-inline-block"
          >
            <div className="side-panel-link-text">
              <span style={{ margin: "0 0.5rem" }}>
                <FontAwesomeIcon icon={faDiscord}></FontAwesomeIcon>
              </span>
              Discord
            </div>
          </a>
        </div>
      </div>
      {/* <div className="arrow" style={{position:"fixed", bottom:"0", left:"0", color:"#fff"}}>
<Arrow />
</div> */}
    </>
  );
};

export default SidePanel;
