import React, { useRef, useLayoutEffect } from "react";
import { gsap, Power3 } from "gsap";
import useElementObserver from "../hooks/useElementObserver";
import TeaserVid from "../videos/Final_logo_teaser.mp4";

const InfoSection = () => {
  const containerRef = useRef(null);
  const slideOne = useRef(null);
  const slideTwo = useRef(null);

  useLayoutEffect(() => {
    gsap.set(containerRef.current, {
      overflow: "hidden",
    });
    gsap.set(slideTwo.current, {
      opacity: 0,
    });
  });

  useElementObserver(
    () => {
      gsap.to(containerRef.current, {
        opacity: 1,
        duration: 1.4,
      });
    },
    containerRef,
    { threshold: 0.25 }
  );

  useElementObserver(() => {
    const tl = gsap.timeline();
    const slideOneText = slideOne.current.firstChild;
    const slideOneImg = slideOne.current.lastChild;

    tl.to(
      slideOneText,
      {
        x: 0,
        duration: 1.4,
        ease: Power3.easeOut,
      },
      0.2
    )
      .to(slideOneImg, { x: 0, duration: 1.8, ease: Power3.easeOut }, 0.2)
      .to(
        slideOneImg,
        {
          borderTop: "3px solid #B67318",
          duration: 0.8,
          ease: Power3.easeOut,
        },
        1.1
      )
      .to(
        slideOneImg,
        {
          borderLeft: "3px solid #B67318",
          duration: 0.8,
          ease: Power3.easeOut,
        },
        1.3
      )
      .to(
        slideOneImg,
        {
          borderBottom: "3px solid #B67318",
          duration: 0.8,
          ease: Power3.easeOut,
        },
        1.5
      );
  }, slideOne);

  useElementObserver(() => {
    const tl = gsap.timeline();
    const slideTwoElement = slideTwo.current;
    const slideTwoCaption = slideTwo.current.firstChild;
    const slideTwoMobileCaption = slideTwo.current.children[1];

    tl.to(slideTwoElement, {
      opacity: 1,
      duration: 1.8,
      ease: Power3.easeOut,
    })
      .to(slideTwoCaption, {
        opacity: 0,
        duration: 2,
        ease: Power3.easeOut,
      })
      .to(
        slideTwoMobileCaption,
        {
          x: 0,
          duration: 1.4,
          ease: Power3.easeOut,
        },
        0.2
      );
  }, slideTwo);
  return (
    <>
      <div
        ref={containerRef}
        style={{ opacity: 0, position: "relative" }}
        className="section wf-section"
      >
        <div className="container btf">
          <div ref={slideOne} className="feature">
            <div
              className="div-block-19"
              style={{ transform: `translate(${-1200}px, ${0}px)` }}
              id="Rewards"
            >
              <div className="success-heading-block">
                <div className="div-block-20">
                  <img
                    src={require("../images/man-soldier.png")}
                    loading="lazy"
                    alt=""
                    className="image"
                  />
                </div>
                <div className="div-block-21 no-margin-mobile">
                  <h2 className="h2-hollow hollow">CRYPTONIAN</h2>
                  <h2 className="h2-full offset">CITIZEN REWARDS</h2>
                </div>
              </div>
              <div className="paragraph-wrap-success">
                <p className="paragraph">
                  <ul style={{ padding: "0 0 0 20px" }}>
                    <li>
                      Each Citizen (NFT Holder) is assigned full ownership /
                      commercial rights
                    </li>
                    <li>1,200 Exclusive Whitelist Opportunities (Pre-Mint)</li>
                    <li>800 Surprise FREE Airdropped NFTs (During mint)</li>
                    <li>
                      $150,000 GiveBack to 75 random Citizens in $2,000
                      increments (During Mint, in Eth)
                    </li>
                    <li>
                      $80,000 GiveBack to 11 Citizens @ 3, 5, and 7 days after
                      Mint (in Eth){" "}
                    </li>
                    <li>
                      1155 Token standard enabling upgradable NFTs to enhance
                      rarity and value{" "}
                    </li>
                    <li>
                      The satisfaction of knowing The Cryptonian War donates 10%
                      to local charities{" "}
                    </li>
                  </ul>
                </p>
              </div>
            </div>

            <div
              className="image-wrapper-1"
              style={{ transform: `translate(${1200}px, ${0}px)` }}
            >
              <div>
                <img
                  src={require("../images/man2-soldier.png")}
                  loading="lazy"
                  alt=""
                  className="image"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container video">
          <div className="feature video">
            <div ref={slideTwo} className="hero">
              <div className="caption">
                <h2 className="h2-hollow hollow">ANTICIPATE</h2>
                <h2 className="h2-full offset">THE NFT PROJECT OF THE YEAR</h2>
              </div>

              <div className="caption mobile">
                <h2 className="h2-hollow hollow">ANTICIPATE</h2>
                <h2 className="h2-full offset">THE NFT PROJECT OF THE YEAR</h2>
              </div>
              <video style={{ width: "100%" }} controls autoPlay muted loop>
                <source src={TeaserVid} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoSection;
