import React, { useRef, useLayoutEffect } from "react";
import { gsap } from "gsap";
import Faq from "react-faq-component";
import useElementObserver from "../hooks/useElementObserver";

const Questions = () => {
  const data = {
    title: "Frequently asked questions",
    rows: [
      {
        title: "I’m new to this world! What is a NFT?",
        content: `<ul>
        <li>A NFT is a non-fungible token which is a non-interchangeable unit of data. This token is stored on a blockchain, particularly Ethereum, which is a digital ledger. NFTs can be purchased, sold, or traded as the owner wills.</li>
        <li>NFTs may be associated with digital files such as photos, videos, and audio. Because each token is uniquely identifiable, NFTs differ from blockchain cryptocurrencies, such as Bitcoin.</li>
        </ul>
        `,
      },
      {
        title: "Where can I buy a Cryptonian Warrior?",
        content: `<a href="https://opensea.io/Cryptonianwar">Link to opensea</a>`,
      },
      {
        title: "What are the rankings of the rarity?",
        content: `To verify our rankings, click to see Cryptonian War on <a href="https://rarity.tools/cryptonianwar">https://rarity.tools/cryptonianwar</a> `,
      },
      {
        title: "What are the royalties?",
        content:
          "We charge 3% royalties; OpenSea charges 2.5% commission; Total Commission 5.5%",
      },
      {
        title: "What is theReserve?",
        content:
          "The Reserve offers strategic components rewarding our community holders with unique items to enhance investment. A CW owner will have elite access to upcoming private collections.",
      },
      {
        title: "How are the Cryptonian Warriors distributed?",
        content: `
        <div>
        <ul>
       <li>9888: Public sale</li>
       <li>1200: WhiteList sale</li>
       <li>800: We will GiveBack during the mint to Cryptonian War owners.</li>
        </ul>
        <p>
        Public sale and Whitelist sale will happen simultaneously.  Whitelist holders will receive preferential pricing.
        </p>
        </div>
        `,
      },
      {
        title: "Which wallet should I connect?",
        content: "We highly encourage using MetaMask to complete transactions.",
      },
    ],
  };

  const styles = {
    titleTextColor: "white",
    rowTitleColor: "white",
    arrowColor: "white",
    bgColor: "transparent",
    rowContentColor: "white",
  };
  const config = {
    animate: true,
  };

  /* animation */
  const containerRef = useRef(null);
  useLayoutEffect(() => {
    gsap.set(containerRef.current, { opacity: 0 });
  }, []);
  useElementObserver(
    () => {
      gsap.to(containerRef.current, { opacity: 1, duration: 1 });
    },
    containerRef,
    { threshold: 0 }
  );
  return (
    <div ref={containerRef} className="container" id="Faq">
      <div className="faq-wrapper">
        <Faq data={data} styles={styles} config={config} />
      </div>
    </div>
  );
};

export default Questions;
