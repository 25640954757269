import { useLayoutEffect } from "react";

const useElementObserver = (
  action,
  ref,
  options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.25,
  }
) => {
  const callbackFunction = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      action();
    }
  };

  useLayoutEffect(() => {
    const element = ref.current;
    const observer = new IntersectionObserver(callbackFunction, options);
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);
};

export default useElementObserver;
