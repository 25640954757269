import React, { useState } from "react";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import "./css/webflow.css";
import "./css/prime.css";
import NavBar from "./Components/Navbar";
import RoadMap from "./Components/RoadMap";
import InfoSection from "./Components/InfoSection";
import Footer from "./Components/Footer";
import Creators from "./Components/Creators";
import Home from "./Components/Home";
import Questions from "./Components/Questions";
import Countdown from "react-countdown";

config.autoAddCss = false;

const App = () => {
  const [showContent, setShowContent] = useState(false)

  const renderer = ({ completed, days, hours, minutes, seconds }) => {
    if (completed) {
      setShowContent(true)
      return null;
    }
else{
      return <span style={{display:"flex", alignItems:"center"}}>
        <div className="timer-content">
          <div>DAYS</div>
          {days}
        </div>
      :
      <div className="timer-content">
        <div>HRS</div>
      {hours}
      </div>
      :
      <div className="timer-content">
        <div>MINS</div>
      {minutes}
      </div>
      :
      <div className="timer-content">
       <div>
                 SECS
       </div>

      {seconds}
      </div>
      </span>;
}
  }

  return (
    <div className="App">
      <div className="body-content top-bg-1">
        <div className="top-part dark-overlay">
          <div className="dark-img" />
          <NavBar />
    (   <section
            className="section atf no-overflow wf-section" style={ showContent ? {display:"none"} : {display:"block"}}>
          <div className="container timer-wrapper">
            <h1 style={{textAlign:"center"}}>
             Our Website goes live in
            </h1>
            <h1 className="timer-head space-font">
                <Countdown date={
                  (new Date("May 10, 2022 00:00:00").getTime()) + (604800 * 1000)} renderer={renderer} daysInHours={true} zeroPadDays={2} />
          </h1>
          </div>
          </section>
          {showContent ? (<>
            <Home />
            <InfoSection />
            <RoadMap />
          </>
          ) : ""}
        </div>

        <div className="bottom-part dark-overlay">
          <div className="dark-img" />
          <div className="bg-bottom">
            <div className="top-2">
              <img
                src={require("./images/blue_bg.png")}
                loading="lazy"
                alt="background"
                className="image offset-up"
              />
            </div>
            <div className="bottom">
              <img
                src={require("./images/blue_bg.png")}
                loading="lazy"
                alt="background"
                className="image bottom-image"
              />
            </div>
          </div>
          {showContent ? (<Creators />) : ""}
          <section className="section wf-section">
            {showContent ? (<Questions />) : ""}
            <div className="container sneakpeak">
              <Footer />
            </div>
{ showContent ?  (<div className="disclaimer">
              *Citizen Rewards pending Mint success
            </div>) : ""}
          </section>
        </div>
      </div>
    </div>
  );
}

export default App;
