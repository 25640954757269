import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gsap } from "gsap";
import { faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import useElementObserver from "../hooks/useElementObserver";

const Creators = () => {
  const leadRef = useRef(null);
  const creatorOneRef = useRef(null);
  const creatorTwoRef = useRef(null);
  const creatorThreeRef = useRef(null);
  const creatorFourRef = useRef(null);
  const creatorFiveRef = useRef(null);
  const creatorSixRef = useRef(null);
  const containerRef = useRef(null);

  /* animations */
  useElementObserver(
    () => {
      gsap.fromTo(
        containerRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 1 }
      );
    },
    containerRef,
    { threshold: 0 }
  );

  useElementObserver(() => {
    const tl = gsap.timeline();
    const leadImg = leadRef.current.firstChild;
    const leadTxt = leadRef.current.lastChild;
    const leadIcons = leadRef.current.lastChild.lastChild.lastChild;
    tl.to(leadImg, { x: 0, duration: 1.2 })
      .to(leadTxt, { x: 0, duration: 1.2 }, 0.2)
      .to(leadIcons, { x: 0, duration: 1.2 }, 0.2);
  }, leadRef);
  useElementObserver(() => {
    const tl = gsap.timeline();
    const creatorImg = creatorOneRef.current.firstChild.firstChild;
    const creatorTxt = creatorOneRef.current.firstChild.lastChild;
    const creatorIcons =
      creatorOneRef.current.firstChild.lastChild.lastChild.lastChild;
    tl.to(creatorImg, { x: 0, duration: 1.2 })
      .to(creatorTxt, { x: 0, duration: 1.2 }, 0.2)
      .to(creatorIcons, { x: 0, duration: 1.2 }, 0.2);
  }, creatorOneRef);
  useElementObserver(() => {
    const tl = gsap.timeline();
    const creatorImg = creatorTwoRef.current.firstChild.firstChild;
    const creatorTxt = creatorTwoRef.current.firstChild.lastChild;
    const creatorIcons =
      creatorTwoRef.current.firstChild.lastChild.lastChild.lastChild;
    tl.to(creatorImg, { x: 0, duration: 1.2 })
      .to(creatorTxt, { x: 0, duration: 1.2 }, 0.2)
      .to(creatorIcons, { x: 0, duration: 1.2 }, 0.2);
  }, creatorTwoRef);
  useElementObserver(() => {
    const tl = gsap.timeline();
    const creatorImg = creatorThreeRef.current.firstChild.firstChild;
    const creatorTxt = creatorThreeRef.current.firstChild.lastChild;
    const creatorIcons =
      creatorThreeRef.current.firstChild.lastChild.lastChild.lastChild;
    tl.to(creatorImg, { x: 0, duration: 1.2 })
      .to(creatorTxt, { x: 0, duration: 1.2 }, 0.2)
      .to(creatorIcons, { x: 0, duration: 1.2 }, 0.2);
  }, creatorThreeRef);
  useElementObserver(() => {
    const tl = gsap.timeline();
    const creatorImg = creatorFourRef.current.firstChild.firstChild;
    const creatorTxt = creatorFourRef.current.firstChild.lastChild;
    const creatorIcons =
      creatorFourRef.current.firstChild.lastChild.lastChild.lastChild;
    tl.to(creatorImg, { x: 0, duration: 1.2 })
      .to(creatorTxt, { x: 0, duration: 1.2 }, 0.2)
      .to(creatorIcons, { x: 0, duration: 1.2 }, 0.2);
  }, creatorFourRef);
  useElementObserver(() => {
    const tl = gsap.timeline();
    const creatorImg = creatorFiveRef.current.firstChild.firstChild;
    const creatorTxt = creatorFiveRef.current.firstChild.lastChild;
    const creatorIcons =
      creatorFiveRef.current.firstChild.lastChild.lastChild.lastChild;
    tl.to(creatorImg, { x: 0, duration: 1.2 })
      .to(creatorTxt, { x: 0, duration: 1.2 }, 0.2)
      .to(creatorIcons, { x: 0, duration: 1.2 }, 0.2);
  }, creatorFiveRef);
  useElementObserver(() => {
    const tl = gsap.timeline();
    const creatorImg = creatorSixRef.current.firstChild.firstChild;
    const creatorTxt = creatorSixRef.current.firstChild.lastChild;
    const creatorIcons =
      creatorSixRef.current.firstChild.lastChild.lastChild.lastChild;
    tl.to(creatorImg, { x: 0, duration: 1.2 })
      .to(creatorTxt, { x: 0, duration: 1.2 }, 0.2)
      .to(creatorIcons, { x: 0, duration: 1.2 }, 0.2);
  }, creatorSixRef);

  return (
    <>
      <section
        ref={containerRef}
        id="About-Us"
        className="section no-overflow wf-section"
        style={{ opacity: 0 }}
      >
        <div className="container created-by">
          <div className="div-block-10">
            <h2 className="h2-smaller" style={{ marginBottom: "1.78rem" }}>
              CREATED <span className="hollow">BY</span>
            </h2>
            <div className="div-block-11">
              <p className="paragraph">
                The founders of The Cryptonian War are serial entrepreneurs with
                an eye for success. Our aim is to bring our domain knowledge,
                pragmatic thought, and ingenuity to bear and provide an
                unparalleled value proposition to our community and generate
                continued market value.
                <br /> <br />
                The Cryptonian War solution’s current scope has a specific focal
                point within the pain points of chain network demand and
                utilization.
                <br /> <br /> We have specifically chosen and designed our Smart
                Contact on the ERC-1155 token standard to maximize the
                useability of both the semi-fungible and non-fungible
                attributes. This puts our community, “Fellow Cryptonian
                Citizens” in a prime position to take advantage of our present
                and future utility. Each Citizen will benefit from batch minting
                to lower gas fees, a safe transfer function, our upcoming
                Cryptonian “War Tokens”, and upgradeable NFT’s that can enhance
                your rarity as our brand grows.
                <br /> <br /> Join our war as the spoils will be great!
              </p>
            </div>
          </div>
        </div>
        <div className="container monkey-container">
          <div className="creators-wrapper">
            <div role="list" className="creators-grid splide__list w-dyn-items">
              <div
                role="listitem"
                className="created-item splide__slide w-dyn-item lead-artist"
              >
                <div className="picture-frame" ref={leadRef}>
                  <div
                    className="creator-photo"
                    style={{
                      borderColor: "#B76318",
                    }}
                  >
                    <img
                      src={require("../images/man-soldier.png")}
                      loading="lazy"
                      alt=""
                      className="image"
                    />
                  </div>
                  <div className="creator-info-wrapper">
                    <div className="name-block">
                      <h3 className="h3-name">Madn art</h3>
                    </div>
                    <div className="div-block-12 lead-artist-txt">
                      <div className="creator-description">Designer</div>
                      <div className="creator-social">
                        <FontAwesomeIcon
                          size="2x"
                          icon={faInstagram}
                          className="social-icon"
                        ></FontAwesomeIcon>
                        <FontAwesomeIcon
                          size="2x"
                          icon={faTwitter}
                          className="social-icon"
                        ></FontAwesomeIcon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* others */}
              <div className="other-artists">
                {/* first start */}
                {/* <div className="first-section"> */}
                {/* 4 */}
                <div
                  role="listitem"
                  className="created-item splide__slide w-dyn-item"
                  ref={creatorOneRef}
                  // style={{transform:`translate(-${100}vw, 0px)`}}
                >
                  <div className="picture-frame">
                    <div className="creator-photo reverse">
                      <img
                        src={require("../images/woman-soldier.png")}
                        loading="lazy"
                        alt=""
                        className="image contain"
                        style={{ objectPosition: "center" }}
                      />
                    </div>
                    <div className="creator-info-wrapper">
                      <div className="name-block">
                        <h3 className="h3-name">Jay mo</h3>
                      </div>
                      <div className="div-block-12">
                        <div className="creator-description">Business</div>
                        <div className="creator-social">
                          <FontAwesomeIcon
                            size="2x"
                            icon={faInstagram}
                          ></FontAwesomeIcon>
                          <FontAwesomeIcon
                            size="2x"
                            icon={faTwitter}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 5 */}
                <div
                  role="listitem"
                  className="created-item splide__slide w-dyn-item"
                  ref={creatorTwoRef}
                >
                  <div className="picture-frame">
                    <div className="creator-photo">
                      <img
                        src={require("../images/woman-soldier.png")}
                        loading="lazy"
                        alt=""
                        className="image contain"
                        style={{ objectPosition: "center" }}
                      />
                    </div>
                    <div className="creator-info-wrapper reverse">
                      <div className="name-block">
                        <h3 className="h3-name"> RAM</h3>
                      </div>
                      <div className="div-block-12">
                        <div className="creator-description">
                          Project Manager
                        </div>
                        <div className="creator-social">
                          <FontAwesomeIcon
                            size="2x"
                            icon={faInstagram}
                          ></FontAwesomeIcon>
                          <FontAwesomeIcon
                            size="2x"
                            icon={faTwitter}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  role="listitem"
                  className="created-item splide__slide w-dyn-item"
                  ref={creatorThreeRef}
                >
                  <div className="picture-frame">
                    <div className="creator-photo">
                      <img
                        src={require("../images/man2-soldier.png")}
                        loading="lazy"
                        alt=""
                        className="image contain"
                        style={{ objectPosition: "center" }}
                      />
                    </div>
                    <div className="creator-info-wrapper reverse">
                      <div className="name-block">
                        <h3 className="h3-name">DaGo</h3>
                      </div>
                      <div className="div-block-12">
                        <div className="creator-description">Lead Dev</div>
                        <div className="creator-social">
                          <FontAwesomeIcon
                            size="2x"
                            icon={faInstagram}
                          ></FontAwesomeIcon>
                          <FontAwesomeIcon
                            size="2x"
                            icon={faTwitter}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                {/* first end */}
                {/* second start */}
                {/* <div className="second-section"> */}
                <div
                  role="listitem"
                  className="created-item splide__slide w-dyn-item"
                  ref={creatorFourRef}
                >
                  <div className="picture-frame">
                    <div className="creator-photo reverse">
                      <img
                        src={require("../images/man2-soldier.png")}
                        loading="lazy"
                        alt=""
                        className="image contain"
                        style={{ objectPosition: "center" }}
                      />
                    </div>
                    <div className="creator-info-wrapper">
                      <div className="name-block">
                        <h3 className="h3-name">Lin Mar</h3>
                      </div>
                      <div className="div-block-12">
                        <div className="creator-description">Marketing</div>
                        <div className="creator-social">
                          <FontAwesomeIcon
                            size="2x"
                            icon={faInstagram}
                          ></FontAwesomeIcon>
                          <FontAwesomeIcon
                            size="2x"
                            icon={faTwitter}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  role="listitem"
                  className="created-item splide__slide w-dyn-item"
                  ref={creatorFiveRef}
                >
                  <div className="picture-frame">
                    <div className="creator-photo">
                      <img
                        src={require("../images/woman-soldier.png")}
                        loading="lazy"
                        alt=""
                        className="image contain"
                        style={{ objectPosition: "center" }}
                      />
                    </div>
                    <div className="creator-info-wrapper reverse">
                      <div className="name-block">
                        <h3 className="h3-name">Niche</h3>
                      </div>
                      <div className="div-block-12">
                        <div className="creator-description">Advisor</div>
                        <div className="creator-social">
                          <FontAwesomeIcon
                            size="2x"
                            icon={faInstagram}
                          ></FontAwesomeIcon>
                          <FontAwesomeIcon
                            size="2x"
                            icon={faTwitter}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  role="listitem"
                  className="created-item splide__slide w-dyn-item"
                  ref={creatorSixRef}
                >
                  <div className="picture-frame">
                    <div className="creator-photo">
                      <img
                        src={require("../images/woman-soldier.png")}
                        loading="lazy"
                        alt=""
                        className="image contain"
                        style={{ objectPosition: "center" }}
                      />
                    </div>
                    <div className="creator-info-wrapper reverse">
                      <div className="name-block">
                        <h3 className="h3-name"> Naomi</h3>
                      </div>
                      <div className="div-block-12">
                        <div className="creator-description">Editor</div>
                        <div className="creator-social">
                          <FontAwesomeIcon
                            size="2x"
                            icon={faInstagram}
                          ></FontAwesomeIcon>
                          <FontAwesomeIcon
                            size="2x"
                            icon={faTwitter}
                          ></FontAwesomeIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                {/* second end */}
                {/* 2 */}

                {/* 3 */}

                {/* 6 */}

                {/* 7 */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Creators;
