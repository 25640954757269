import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  Link
} from 'react-scroll'
import { gsap, Power3 } from "gsap";
import useElementObserver from "../hooks/useElementObserver";

const Footer = () => {
  const footerContainerRef = useRef(null);
  const footerMobile = useRef(null);
  const footerDesktop = useRef(null);

  useElementObserver(() => {
    const tl = gsap.timeline();
    tl.to(footerMobile.current, {
      opacity: 1,
      duration: 1.6,
      ease: Power3.easeInOut,
    });
  }, footerContainerRef);
  useElementObserver(() => {
    const tl = gsap.timeline();
    tl.to(footerDesktop.current, {
      opacity: 1,
      duration: 1.6,
      ease: Power3.easeInOut,
    });
  }, footerContainerRef);
  return (
    <>
      <div
        style={{ position: "relative" }}
        ref={footerContainerRef}
        id="footer"
      >
        {<hr className="footer-divider" />}
        <div className="footer-mobile" ref={footerMobile}>
          <div className="logo-block-footer">
            <img
              src={require("../images/cw-logo.png")}
              loading="lazy"
              alt="logo"
              className="image"
            />
          </div>
          <div style={{display:"flex", width:"100%", justifyContent:"center"}}>
            <div style={{ marginRight:"1rem"}} className="nav-text">
              <a href="https://discord.gg/H5NnngKFkG"
                target="_blank">
            <FontAwesomeIcon size="2x" icon={faDiscord}></FontAwesomeIcon>
              </a>
          </div>
            <div style={{ marginRight: "1rem" }} className="nav-text">
              <a href="https://www.instagram.com/cryptonianwar/"
                target="_blank">
              <FontAwesomeIcon size="2x" icon={faInstagram}></FontAwesomeIcon>
              </a>
            </div>
            <div className="nav-text">
              <a href="https://twitter.com/cryptonianwar"
                target="_blank">
              <FontAwesomeIcon size="2x" icon={faTwitter}></FontAwesomeIcon>
              </a>
            </div>

          </div>
          <div className="footer-social">
            <Link to="Rewards" smooth={true}
              className="nav-link w-inline-block"
              style={{ marginLeft: "0" }}
            >
              <div className="nav-text">
         
                REWARDS
              </div>
            </Link>
            <Link to="Road-Map" smooth={true} className="nav-link w-inline-block">
              <div className="nav-text">
         
                ROADMAP
              </div>
            </Link>
            <Link to="About-Us" smooth={true} className="nav-link w-inline-block">
              <div className="nav-text">

                ABOUT US
              </div>
            </Link>
          </div>
    
        </div>
        {/* desktop footer */}

        <div
          data-w-id="d9ac1eb4-9343-d488-ed9b-2a782833a308"
          className="footer"
          style={{ zIndex: "90", opacity: "0" }}
          ref={footerDesktop}
        >
          <div className="logo-block-footer">
            <img
              src={require("../images/cw-logo.png")}
              loading="lazy"
              alt="logo"
              className="image"
            />
          </div>
          <div style={{ display: "flex" }}>
            <Link to="Rewards" smooth={true} className="nav-link first w-inline-block">
              <div className="nav-text">REWARDS</div>
            </Link>
            <Link to="Road-Map" smooth={true} className="nav-link w-inline-block">
              <div className="nav-text">ROADMAP</div>
            </Link>
            <Link to="About-Us" smooth={true} className="nav-link w-inline-block">
              <div className="nav-text">ABOUT US</div>
            </Link>
            <a href="https://discord.gg/H5NnngKFkG" target="_blank" className="nav-link w-inline-block">
              <div className="nav-text">
                <FontAwesomeIcon size="2x" icon={faDiscord}></FontAwesomeIcon>
              </div>
            </a>
            <a
              href="https://www.instagram.com/cryptonianwar/"
              className="nav-link w-inline-block"
              style={{ marginLeft: "1rem" }}
            >
              <div className="nav-text">
                <FontAwesomeIcon size="2x" icon={faInstagram}></FontAwesomeIcon>
              </div>
            </a>
            <a
              href="https://twitter.com/cryptonianwar"
              className="nav-link w-inline-block"
              style={{ marginLeft: "1rem" }}
            >
              <div className="nav-text">
                <FontAwesomeIcon size="2x" icon={faTwitter}></FontAwesomeIcon>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
