import React, { useState } from "react";
import {
  Link
} from 'react-scroll'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { ReactComponent as OpenSeaIcon } from "../images/open_sea-icon.svg";

const NavBar = () => {
  const [isMobile, setIsMobile] = useState(false);
  return (
    <>
      <div
        className="mobile-menu"
        style={
          isMobile
            ? { transform: `translateX(${20}vw)` }
            : { transform: `translateX(${100}vw)` }
        }
      >
        <div className="mobile-wrap">
          <Link to="Rewards" smooth={true} className="mobile-menu-link w-inline-block">
            <div className="mobile-menu-text">REWARDS</div>
          </Link>
          <Link to="Road-Map" smooth={true} className="mobile-menu-link w-inline-block">
            <div className="mobile-menu-text">ROADMAP </div>
          </Link>
          <Link to="About-Us" smooth={true} className="mobile-menu-link w-inline-block">
            <div className="mobile-menu-text">ABOUT US</div>
          </Link>
          <Link to="Faq" smooth={true} className="mobile-menu-link w-inline-block">
            <div className="mobile-menu-text">FAQs</div>
          </Link>
        </div>
      </div>
      {/* desktop nav */}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "9em",
          padding: "2rem 0 0 1rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <div className="logo-block-m">
          <img
            src={require("../images/cw-logo.png")}
            loading="lazy"
            alt="logo"
            className="image"
          />
        </div> */}
        <div className="logo-block">
          <img
            src={require("../images/cw-logo.png")}
            loading="lazy"
            alt="logo"
            className="image"
          />
        </div>

        <div
          data-w-id="d9ac1eb4-9343-d488-ed9b-2a782833a308"
          className="navbar"
          style={{ zIndex: "90", position:"relative" }}
        >
          <div className="nav-desktop">
            <Link to="Rewards" smooth={true} className="nav-link first w-inline-block">
              <div className="nav-text" role="button">REWARDS</div>
            </Link>
            <Link to="Road-Map" smooth={true}  className="nav-link w-inline-block">
              <div className="nav-text" role="button">ROADMAP</div>
            </Link>
            <Link to="About-Us" smooth={true}  className="nav-link w-inline-block">
              <div className="nav-text" role="button">ABOUT US</div>
            </Link>
            <Link to="Faq" smooth={true}  className="nav-link w-inline-block">
              <div className="nav-text" role="button">FAQ&apos;s</div>
            </Link>
            <a href="#" className="nav-link w-inline-block">
              <div className="nav-text">
                <a
                  style={{ color: "#fff" }}
                  href="https://discord.gg/H5NnngKFkG"
                  target="_blank"
                >
                  {" "}
                  <FontAwesomeIcon size="2x" icon={faDiscord}></FontAwesomeIcon>
                </a>
              </div>
            </a>
            <a
              href="#"
              className="nav-link w-inline-block"
              style={{ marginLeft: "0.7rem" }}
            >
              <div className="nav-text">
                <a
                  style={{ color: "#fff" }}
                  href="https://www.instagram.com/cryptonianwar/"
                  target="_blank"
                >
                  {" "}
                  <FontAwesomeIcon
                    size="2x"
                    icon={faInstagram}
                  ></FontAwesomeIcon>
                </a>
              </div>
            </a>
            <a
              href="#"
              className="nav-link w-inline-block"
              style={{ marginLeft: "0.7rem" }}
            >
              <div className="nav-text">
                <a
                  style={{ color: "#fff" }}
                  href="https://twitter.com/cryptonianwar"
                  target="_blank"
                >
                  {" "}
                  <FontAwesomeIcon icon={faTwitter} size="2x"></FontAwesomeIcon>
                </a>
              </div>
            </a>
            <a
              href="#"
              className="nav-link w-inline-block"
              style={{ marginLeft: "-0.2rem" }}
            >
              <div className="nav-text">
                <a
                  style={{ color: "#fff" }}
                  href="https://opensea.io/Cryptonianwar"
                  target="_blank"
                >
                  <OpenSeaIcon />
                </a>
              </div>
            </a>
          </div>
          <button
     
            className="mobile-burger"
            style={ isMobile ? {position:"fixed", right:"5px"} : {position:"relative", right:"auto"}}
            onClick={() => setIsMobile(!isMobile)}
  
          >
            <div
              className="mobile-burger-line"
              style={
                isMobile
                  ? { transform: `rotate(${45}deg)`, width: "1.5rem" }
                  : { transform: `rotate(${0})` }
              }
            />
            <div
              className="mobile-burger-line"
              style={
                isMobile
                  ? { transform: `translateX(${20}px)`, opacity: 0 }
                  : { transform: `translateX(${0})`, opacity: 1 }
              }
            />
            <div
              className="mobile-burger-line"
              style={
                isMobile
                  ? { transform: `rotate(${-45}deg)`, width: "1.5rem" }
                  : { transform: `rotate(${0})` }
              }
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default NavBar;
