import React, { useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import BananaIcon from "../images/spaceShip.png";
import useElementObserver from "../hooks/useElementObserver";

const RoadMap = () => {
  const [iconHeight, setIconHeight] = useState(0);
  gsap.registerPlugin(ScrollTrigger);
  const containerRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const iconRef = useRef(null);

  /* animation */
  useLayoutEffect(() => {
    gsap.set(containerRef.current, { opacity: 0 });
  }, []);
  useElementObserver(
    () => {
      gsap.to(containerRef.current, {
        opacity: 1,
        duration: 1.6,
      });
    },
    containerRef,
    { threshold: 0 }
  );

  useLayoutEffect(() => {
    gsap.fromTo(
      iconRef.current,
      {
        height: "0%",
      },
      {
        height: "100%",
        onUpdate: function () {
          setIconHeight(parseFloat(iconRef.current.style.height));
        },
        scrollTrigger: {
          trigger: scrollContainerRef.current,
          start: "top top",
          end: "bottom center",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <>
      <div id="Road-Map" className="section wf-section" ref={containerRef}>
        <div className="spaceShip-bg" />
        <div className="container roadmap-container">
          <div className="roadmap-wrapper">
            <div className="roadmap-block">
              <h2 className="h2-hollow hollow">CRYPTONIAN WAR</h2>
              <h2 className="h2-full offset">Roadmap</h2>
            </div>
            <div
              data-w-id="5c7d63c2-12e2-dfd6-0243-3c535757e287"
              className="roadmap-content-block"
            >
              <div className="roadmap-sticky">
                <div className="roadmap-sticky-content">
                  <div className="number-tracker">
                    <div className="changing-numbers-block">
                      <div
                        className={`changing-numbers ${
                          iconHeight <= 20 ? "is--active" : ""
                        }`}
                      >
                        I{" "}
                      </div>
                      <div
                        className={`changing-numbers ${
                          iconHeight > 20 && iconHeight <= 35
                            ? "is--active"
                            : ""
                        }`}
                      >
                        II
                      </div>
                      <div
                        className={`changing-numbers ${
                          iconHeight > 35 && iconHeight <= 62
                            ? "is--active"
                            : ""
                        }`}
                      >
                        III
                      </div>
                      <div
                        className={`changing-numbers ${
                          iconHeight > 62 && iconHeight <= 77
                            ? "is--active"
                            : ""
                        }`}
                      >
                        IV
                      </div>
                      <div
                        className={`changing-numbers ${
                          iconHeight > 77 && iconHeight <= 88
                            ? "is--active"
                            : ""
                        }`}
                      >
                        V
                      </div>
                      <div
                        className={`changing-numbers ${
                          iconHeight > 88 && iconHeight <= 95
                            ? "is--active"
                            : ""
                        }`}
                      >
                        VI
                      </div>
                      <div
                        className={`changing-numbers ${
                          iconHeight > 95 && iconHeight <= 100
                            ? "is--active"
                            : ""
                        }`}
                      >
                        VII
                      </div>
                    </div>
                    {/* <div className="number-total total">/06</div> */}
                  </div>
                  <div className="banana-bar">
                    <div className="end-points" />
                    <div className="bar">
                      <div ref={iconRef} className="banana-scroll">
                        <div className="div-block-7" />
                        <img
                          src={BananaIcon}
                          loading="lazy"
                          alt="banana scroll"
                          className="banana-image"
                        />
                      </div>
                    </div>
                    <div className="end-points" />
                  </div>
                  <div className="changing-words-block">
                    <h3
                      className={`h3-roadmap absolute ${
                        iconHeight <= 20 ? "is--actived" : ""
                      }`}
                    >
                      A New Galaxy
                      <strong>
                        <br />
                      </strong>
                    </h3>
                    <h3
                      className={`h3-roadmap absolute ${
                        iconHeight > 20 && iconHeight <= 35 ? "is--actived" : ""
                      }`}
                    >
                      <strong>Pre-Launch</strong>
                      <strong>
                        <br />
                      </strong>
                    </h3>
                    <h3
                      className={`h3-roadmap absolute ${
                        iconHeight > 35 && iconHeight <= 62 ? "is--actived" : ""
                      }`}
                    >
                      <strong>
                        Minting: 9,888 Cryptonian Warriors / $150K GiveBack{" "}
                      </strong>
                      <strong>
                        <br />
                      </strong>
                    </h3>
                    <h3
                      className={`h3-roadmap absolute ${
                        iconHeight > 62 && iconHeight <= 77 ? "is--actived" : ""
                      }`}
                    >
                      <strong>Victory Eth Rewards </strong>
                      <strong>
                        <br />
                      </strong>
                    </h3>
                    <h3
                      className={`h3-roadmap absolute ${
                        iconHeight > 77 && iconHeight <= 88 ? "is--actived" : ""
                      }`}
                    >
                      <strong>3D Cryptonian Villains </strong>
                      <strong>
                        <br />
                      </strong>
                    </h3>
                    <h3
                      className={`h3-roadmap absolute ${
                        iconHeight > 88 && iconHeight <= 95 ? "is--actived" : ""
                      }`}
                    >
                      <strong>
                        The Cryptonian War on Rarity.Tools and Rarity Sniper
                      </strong>
                      <strong>
                        <br />
                      </strong>
                    </h3>
                    <h3
                      className={`h3-roadmap absolute ${
                        iconHeight > 95 && iconHeight <= 100
                          ? "is--actived"
                          : ""
                      }`}
                    >
                      <strong>Temporal Rift</strong>
                      <strong>
                        <br />
                      </strong>
                    </h3>
                  </div>
                </div>
              </div>
              <div ref={scrollContainerRef} className="rodmap-paragraphs-block">
                <div className="roadmap-content _1">
                  <p className="paragraph">
                    The genesis collection of 9,888 Cryptonian Warriors are
                    being commissioned Follow us on Twitter, Discord, and
                    Instagram for daily updates.
                    <br />
                  </p>
                </div>
                <div className="roadmap-content _2">
                  <p className="paragraph">
                    The launch pad into the dimension begins with our whitelist
                    event and public mint launching simultaneously. Connect your
                    wallet for the exclusive 1200 NFTs in our whitelist and
                    increase your chance of winning one of our 800 FREE NFTs to
                    be given away during the mint.
                  </p>
                </div>
                <div className="roadmap-content _3">
                  <p className="paragraph">
                    A unique collection of Cryptonian Warriors dressed in
                    superhero garb are ready for battle. As warriors are
                    commissioned in the mint, each owner will receive a chance
                    to win $2K in our massive $150,000 (in Eth) GiveBack to the
                    community. If you win, share on your social platform to
                    increase your influence to GiveBack.
                  </p>
                </div>
                <div className="roadmap-content _4">
                  <p className="paragraph">
                    A chant of victory will be heard as the Warriors band
                    together. Upon successful minting, we will award (8) $5,000
                    GiveBack bonuses to random Cryptonian War owners within 72
                    hours after the mint. On Day 5, we will GiveBack $10,000
                    each (in Eth) to two (2) CW citizens. On Day 7, one CW
                    citizen will receive $20,000 deposited into their wallet.
                    This GiveBack marks the victory of the Cryptonian Warriors.
                  </p>
                </div>
                <div className="roadmap-content _5" style={{ height: "10em" }}>
                  <p className="paragraph">
                    A unique collection of Cryptonian villains challenging our
                    Warriors.
                  </p>
                </div>
                <div
                  className="roadmap-content _6"
                  style={{ height: "10em", justifyContent: "flex-start" }}
                >
                  <p className="paragraph">2nd 10% Local Charity Donations</p>
                </div>
                <div className="roadmap-content _7">
                  <p className="paragraph">
                    The Cryptonian Warrior experience a time shift in which they
                    are sub-atomically changed into 3D warriors. This collection
                    is then given post mint upgradeability via our Smart
                    Contract built on ERC-1155. Increase rarity and value as our
                    brand continues to moon.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoadMap;
